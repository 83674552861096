/* Framework imports ----------------------------------- */

/* Module imports -------------------------------------- */
import { useLocalStorage } from './useLocalStorage';

/* Create the persisted state hook for the auth token -- */

/* useAuthToken hook ----------------------------------- */
export const useAuthToken = (): [string, (pNewAuthToken: string) => void] => {
  return useLocalStorage('authToken', '');
};
