/* Module imports -------------------------------------- */
import { csFetch } from './fetch/fetch';
import { login } from './login/login';
import { testAuth } from './testAuth/testAuth';
import { fetchDocs } from './documents/fetchDocs';
import { fetchFile } from './file/fetchFile';
import { fetchImage } from './image/fetchImage';
import { fetchUserInfo } from './user/fetchUserInfo';
import { fetchFeed } from './feed/fetchFeed';
import { fetchUserAddToken } from './user/fetchUserAddToken';
import { fetchUserRemoveToken } from './user/fetchUserRemoveToken';
import { fetchReference } from './reference/fetchReference';

/* Export MobileAPI module ----------------------------- */
export default {
  fetch: csFetch,
  login: login,
  testAuth: testAuth,
  fetchDocs: fetchDocs,
  fetchFile: fetchFile,
  fetchImage: fetchImage,
  fetchUserInfo: fetchUserInfo,
  fetchFeed: fetchFeed,
  fetchUserAddToken: fetchUserAddToken,
  fetchUserRemoveToken: fetchUserRemoveToken,
  fetchReference: fetchReference,
};
