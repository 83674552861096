/* Module imports -------------------------------------- */
import { csFetch } from '../fetch/fetch';

/* Type imports ---------------------------------------- */
import type { FetchResponse } from '../fetch/FetchResponse';
import { HTTPMethods } from '../../../types/HTTPMethods';
import { CSFetchUserAddTokenReturnType } from './CSFetchUserAddTokenReturnType';
import { FetchResult } from '../fetch/FetchResult';

/* fetchUserAddToken function -------------------------- */
export const fetchUserAddToken = async (token: string): Promise<CSFetchUserAddTokenReturnType> => {
  const lReturnObj: CSFetchUserAddTokenReturnType = {
    result: '',
    statusCode: 0,
  };

  try {
    const lFetchResponse: FetchResponse = await csFetch(
      '/mobileapi/addtoken',
      HTTPMethods.POST,
      // eslint-disable-next-line camelcase
      { push_token: token },
    );

    lReturnObj.statusCode = lFetchResponse.statusCode;
    lReturnObj.statusText = lFetchResponse.statusText;

    // console.log(`[DEBUG] <MobileAPI.fetchUserAddToken> Request succeeded, got response :`, lFetchResponse);

    // eslint-disable-next-line no-empty
    if(lFetchResponse.body !== undefined) {
      const { result }: FetchResult = lFetchResponse.body as unknown as FetchResult;
      lReturnObj.result = result;
    }
  } catch(pException) {
    console.error(`[ERROR] <MobileAPI.fetchUserAddToken> POST request failed :`, pException);
    throw new Error(`[ERROR] <MobileAPI.fetchUserAddToken> POST request failed.`);
  }

  return lReturnObj;
};
