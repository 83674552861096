/* Framework imports ----------------------------------- */
import React from 'react';

/* Component imports ----------------------------------- */
import {
  IonContent,
  IonPage,
} from '@ionic/react';
import { motion } from 'framer-motion';

/* Style imports --------------------------------------- */
import './ErrorPage.css';

/* Internal variables ---------------------------------- */
const container = {
  hidden: { opacity: 0, duration: 0.5 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.1,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const item2 = {
  hidden: { opacity: 0, scale: 1.2 },
  visible: {
    scale: 0.7,
    opacity: 1,
  },
};

/* ErrorPage component --------------------------------- */
const ErrorPage: React.FC = () => {

  return (
    <IonPage>
      <IonContent fullscreen>
        <motion.div
          variants={container}
          initial="hidden"
          animate="visible"
        >
          <div
            style={
              {
                height: '90px',
                position: 'relative',
                backgroundColor: '#FFF8EB',
                paddingTop: 0,
              }
            }
          >
            <div className="login-smooth">
              <motion.img
                variants={item2}
                src="/assets/logos/bloomdayz-full-logo-transparent.svg"
              />
            </div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path
              fill="#FFF8EB"
              fillOpacity="1"
              d="M0,192L30,186.7C60,181,120,171,180,149.3C240,128,300,96,360,122.7C420,149,480,235,540,245.3C600,256,660,192,720,165.3C780,139,840,149,900,165.3C960,181,1020,203,1080,202.7C1140,203,1200,181,1260,144C1320,107,1380,53,1410,26.7L1440,0L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"
            />
          </svg>
          <motion.div
            variants={item}
            className="login-header"
          >
            Oups !
          </motion.div>
          <motion.div
            variants={item}
            style={{ fontSize: '25px', paddingTop: 5 }}
            className="login-subheader"
          >
            📡
          </motion.div>
          <motion.div
            variants={item}
            className="login-subheader"
          >
            Vous n'êtes pas connecté à internet
          </motion.div>
          <p style={{ padding: 30, fontWeight: 'bold', textAlign: 'center' }}>
            Une connexion internet est requise pour accéder à l'application Bloomdayz.
          </p>
        </motion.div>
      </IonContent>
    </IonPage>
  );
};

/* Export ErrorPage component -------------------------- */
export default ErrorPage;
