/* Framework imports ----------------------------------- */
import React from 'react';

/* Component imports ----------------------------------- */
import MainNavBar from './MainNavBar/MainNavBar';

/* MainLayout component prop types --------------------- */
interface MainLayoutProps {
  authToken: string;
  setAuthToken: (pNewAuthToken: string) => void;
}

/* MainLayout component -------------------------------- */
const MainLayout: React.FC<MainLayoutProps> = ({ authToken, setAuthToken }) => {
  return (
    <MainNavBar
      authToken={authToken}
      setAuthToken={setAuthToken}
    />
  );
};

/* Export MainLayout component ------------------------- */
export default MainLayout;
