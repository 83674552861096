/* Framework imports ----------------------------------- */
import React from 'react';

/* CSButton component ---------------------------------- */
const CSButton: React.FC<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>> = (props) => {
  return (
    <button
      className="Input-button"
      {...props}
      style={
        {
          marginTop: 60,
          ...props.style,
        }
      }
    >
      {props.children}
    </button>
  );
};

/* Export CSButton component --------------------------- */
export default CSButton;
