/* Framework imports ----------------------------------- */

/* Module imports -------------------------------------- */
import RegExLibrary from '../../RegExLibrary';
import ConfigManager from '../../ConfigManager/ConfigManager';

/* Type imports ---------------------------------------- */
import type { FetchResponse } from './FetchResponse';
import { HTTPMethods } from '../../../types/HTTPMethods';

/* MobileAPI fetch ------------------------------------- */
/* Called csFetch to avoid conflicts w/ the JS fetch function */
export const csFetch = async (
  pEndpoint: string,
  pMethod: HTTPMethods = HTTPMethods.GET,
  pBody?: Record<string, unknown>,
  pCustomHeaders?: Headers,
  pGetRawTextOnly: boolean = false,
): Promise<FetchResponse> => {
  /* Check the endpoint */
  if(!pEndpoint.startsWith('/')) {
    console.warn(`[ERROR] <MobileAPI.fetch> Given endpoint doesn't start with '/' :`, pEndpoint);
    pEndpoint = `/${pEndpoint}`;
  }

  /* Build the request URL */
  let lURL: string = ConfigManager.getAPIURL();
  if(!RegExLibrary.domainName.test(lURL)) {
    console.error(`[ERROR] <MobileAPI.fetch> REACT_APP_MOBILE_API_ENDPOINT env variable is not an URL !`);
    throw new Error(`[ERROR] <MobileAPI.fetch> REACT_APP_MOBILE_API_ENDPOINT env variable is not an URL !`);
  }

  if(lURL.endsWith('/')) {
    lURL.slice(0, -1); /* Remove the last character */
  }
  lURL += pEndpoint;

  /* Format the request's payload */
  const lRequestBody: string | undefined = pBody && JSON.stringify(pBody);

  /* Get the authentication token */
  const lAuthToken = localStorage.getItem('authToken');

  /* Build the request's headers */
  const lRequestHeaders: Headers = new Headers();
  lRequestHeaders.append('Connection', 'keep-alive');
  if(pGetRawTextOnly) {
    lRequestHeaders.append('Accept', 'text/plain;charset=UTF-8');
    lRequestHeaders.append('Content-Type', 'text/plain;charset=UTF-8');
  } else {
    lRequestHeaders.append('Accept', 'application/json;charset=UTF-8');
    lRequestHeaders.append('Content-Type', 'application/json;charset=UTF-8');
  }
  lRequestBody !== undefined && lRequestHeaders.append('Content-Length', `${lRequestBody.length}`);
  if(lAuthToken !== null && lAuthToken !== '') {
    lRequestHeaders.append('Authorization', `Bearer ${lAuthToken}`);
  }

  /* Apply our custom headers if they exist */
  if(pCustomHeaders !== undefined) {
    pCustomHeaders.forEach(
      (pValue: string, pKey: string, pParent: Headers) => {
        lRequestHeaders.set(pKey, pValue);
      },
    );
  }

  try {
    /* Send our request */
    const lResponse: Response = await fetch(
      lURL,
      {
        method: pMethod,
        headers: lRequestHeaders,
        body: lRequestBody,
      },
    );

    const lFetchResponseData: FetchResponse = {
      statusCode: lResponse.status,
      statusText: lResponse.statusText,
      body: undefined,
    };

    if(!lResponse.ok) {
      console.error(
        `[ERROR] <MobileAPI.fetch> ${pEndpoint} ${pMethod} request returned status code`,
        lFetchResponseData.statusCode,
        ':',
        lFetchResponseData.statusText,
      );

      /* Return the results as is */
      return lFetchResponseData;
    }

    /* Parse the received JSON data */
    try {

      if(pGetRawTextOnly) {
        lFetchResponseData.raw = await lResponse.text();
      } else {
        lFetchResponseData.body = await lResponse.json() as Record<string, unknown>;
        lFetchResponseData.raw = JSON.stringify(lFetchResponseData.body);
      }
    } catch(pException) {
      console.error(
        `[ERROR] <MobileAPI.fetch> ${pEndpoint} ${pMethod} : Failed to retrieve JSON data :`,
        lFetchResponseData.raw,
      );
    }

    /* Return the response data */
    return lFetchResponseData;

  } catch(pException) {
    console.error(`[ERROR] <MobileAPI.fetch> ${pEndpoint} ${pMethod} request failed :`, pException);
    throw new Error(`[ERROR] <MobileAPI.fetch> ${pEndpoint} ${pMethod} request failed.`);
  }
};
