/* Framework imports ----------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react';

/* Module imports -------------------------------------- */
import MobileAPI from '../../helpers/MobileAPI/MobileAPI';
import { toast } from 'react-toastify';

/* Component imports ----------------------------------- */
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRefresher,
  IonRefresherContent,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import CSAccordion, { CSAccordionDataType } from '../../components/CSAccordion/CSAccordion';
import CSDocumentList from '../../components/CSDocumentList/CSDocumentList';

/* Type imports ---------------------------------------- */
import type { CSDocumentCategoryCollection } from '../../helpers/MobileAPI/documents/CSDocumentCategoryCollection';
import type { RefresherEventDetail } from '@ionic/core';

/* Styling imports ------------------------------------- */
import './DocumentsPage.css';

/* DocumentsPage component prop types ------------------ */
interface DocumentsPageProps {
  authToken: string;
}

/* DocumentsPage component ----------------------------- */
const DocumentsPage: React.FC<DocumentsPageProps> = ({ authToken }) => {
  let componentIsMounted = true; /* The component is mounted */

  const [ documentCategoryCollection, setDocumentCategoryCollection ] = useState<Array<CSDocumentCategoryCollection>>();

  const loadDocumentsData = async (): Promise<void> => {
    try {
      const lDocResults = await MobileAPI.fetchDocs();
      if(lDocResults.statusCode < 300 && lDocResults.statusCode > 199) {
        // console.log(`[DEBUG] <DocumentsPage> Setting documentCategoryCollection :`, lDocResults.documents);
        componentIsMounted && setDocumentCategoryCollection(lDocResults.documents);
      } else {
        componentIsMounted && setDocumentCategoryCollection(undefined);
      }
    } catch(pException) {
      console.error(`[ERROR] <DocumentsPage> Failed to fetch the documents :`, pException);
      toast.error(
        `⚠️ Une erreur s'est produite en récupérant les documents.`,
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
        },
      );
    }
  };

  const onRefresh = (pEvent: CustomEvent<RefresherEventDetail>): void => {
    loadDocumentsData()
      .then(
        () => {
          setTimeout(
            () => {
              pEvent.detail.complete();
            },
            500,
          );
        },
      )
      .catch(
        (pException) => {
          console.error(`[ERROR] <DocumentsPage.onRefresh> loadDocumentsData() failed :`, pException);
        },
      );
  };

  useIonViewDidEnter(
    () => {
      // console.log('[DEBUG] <DocumentsPage> ionViewDidEnter event fired');
    },
  );

  useIonViewDidLeave(
    () => {
      // console.log('[DEBUG] <DocumentsPage> ionViewDidLeave event fired');
    },
  );

  useIonViewWillEnter(
    () => {
      // console.log('[DEBUG] <DocumentsPage> ionViewWillEnter event fired');
      loadDocumentsData()
        .catch(
          (pException) => {
            console.error(`[ERROR] <DocumentsPage> loadDocumentsData() failed :`, pException);
          },
        );
    },
  );

  useIonViewWillLeave(
    () => {
      // console.log('[DEBUG] <DocumentsPage> ionViewWillLeave event fired');
    },
  );

  useEffect(
    () => {
      loadDocumentsData()
        .catch(
          (pException) => {
            console.error(`[ERROR] <DocumentsPage> loadDocumentsData() failed :`, pException);
          },
        );

      return () => {
        /* This callback is run when the component is unmounted */
        // eslint-disable-next-line react-hooks/exhaustive-deps
        componentIsMounted = false;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      authToken,
    ],
  );

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            Documents
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher
          slot="fixed"
          onIonRefresh={onRefresh}
        >
          <IonRefresherContent
            pullingIcon="lines"
            pullingText="Rafraîchir"
            refreshingSpinner="lines"
            refreshingText="Chargement..."
          />
        </IonRefresher>
        <div style={{ width: '100%', textAlign: 'center', padding: 30 }}>
          <h1 style={{ fontSize: '25px' }}>
            📝
          </h1>
          <p>
            <b>
              Les documents Bloomdayz
            </b>
            {' '}
            <br />
            <br />
            <span style={{ color: '#bbbbbb' }}>
              Accédez à la liste des documents importants à garder sous le coude en toutes circonstances lors des stages de vos enfants 👌
              {' '}
            </span>
          </p>
        </div>
        {
          (documentCategoryCollection !== undefined && documentCategoryCollection.length > 0) &&
            <CSAccordion
              contents={
                documentCategoryCollection.map(
                  (
                    pDocCollection: CSDocumentCategoryCollection,
                    pDocIndex: number,
                    pDocCollectionArray: Array<CSDocumentCategoryCollection>,
                  ): CSAccordionDataType => {
                    return {
                      title: pDocCollection.display_name,
                      contents: <CSDocumentList documents={pDocCollection.file_ids} />,
                    };
                  },
                )
              }
            />
        }
      </IonContent>
    </IonPage>
  );
};

/* Export DocumentsPage component ------------------------------- */
export default DocumentsPage;
