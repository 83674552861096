/* Module imports -------------------------------------- */
import { csFetch } from '../fetch/fetch';

/* Type imports ---------------------------------------- */
import type { FetchResponse } from '../fetch/FetchResponse';
import type { CSDocumentCategoryCollection } from './CSDocumentCategoryCollection';
import type { CSFetchDocumentsPayload } from './CSFetchDocumentsPayload';
import type { CSFetchDocumentsReturnType } from './CSFetchDocumentsReturnType';
import { HTTPMethods } from '../../../types/HTTPMethods';

/* fetchDocs function ---------------------------------- */
export const fetchDocs = async (): Promise<CSFetchDocumentsReturnType> => {
  const lReturnObj: CSFetchDocumentsReturnType = {
    statusCode: 0,
  };

  try {
    const lFetchResponse: FetchResponse = await csFetch(
      '/mobileapi/documents',
      HTTPMethods.GET,
    );

    lReturnObj.statusCode = lFetchResponse.statusCode;
    lReturnObj.statusText = lFetchResponse.statusText;

    // console.log(`[DEBUG] <MobileAPI.fetchDocs> Request succeeded, got response :`, lFetchResponse);

    if(lFetchResponse.body?.documents !== undefined) {
      const lPayload: CSFetchDocumentsPayload = {
        documents: lFetchResponse.body?.documents as Array<CSDocumentCategoryCollection>,
      };
      // console.log(`[DEBUG] <MobileAPI.fetchDocs> POST request succeeded, got documents :`, lDocuments);

      lReturnObj.documents = lPayload.documents;
    }
  } catch(pException) {
    console.error(`[ERROR] <MobileAPI.fetchDocs> POST request failed :`, pException);
    throw new Error(`[ERROR] <MobileAPI.fetchDocs> POST request failed.`);
  }

  return lReturnObj;
};
