/* Framework imports ----------------------------------- */
import React, { useState } from 'react';

/* Component imports ----------------------------------- */
import CSAccordionItem from './CSAccordionItem/CSAccordionItem';

/* Style imports --------------------------------------- */
import './CSAccordion.css';

/* CSAccordion content data types ---------------------- */
export interface CSAccordionDataType {
  title: string;
  contents: React.ReactNode;
}

/* CSAccordion component prop types -------------------- */
interface CSAccordionProps {
  contents: Array<CSAccordionDataType>;
}

/* CSAccordion component ------------------------------- */
const CSAccordion: React.FC<CSAccordionProps> = ({ contents }) => {
  const [ activeItem, setActiveItem ] = useState(-1);

  const setItemActive = (pIndex: number) => (pActive: boolean) => {
    if(activeItem === pIndex) {
      if(!pActive) {
        setActiveItem(-1);
      } else {
        /* Nothing to do */
      }
    } else {
      if(pActive) {
        setActiveItem(pIndex);
      }
    }
  };

  return (
    <div className="accordion">
      {
        contents.map(
          (
            pValue: CSAccordionDataType,
            pIndex: number,
            pArray: Array<CSAccordionDataType>,
          ) => {
            const isItemActive = activeItem === pIndex;

            return (
              <CSAccordionItem
                key={pIndex}
                isActive={isItemActive}
                setIsActive={setItemActive(pIndex)}
                title={pValue.title}
                contents={pValue.contents}
              />
            );
          },
        )
      }
    </div>
  );
};

/* Export CSAccordion component ------------------------ */
export default CSAccordion;
