/* Framework imports ----------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react';
import {
  StatusBar,
  Style,
} from '@capacitor/status-bar';

/* Module imports -------------------------------------- */
import { toast } from 'react-toastify';
import RegExLibrary from '../../helpers/RegExLibrary';
import MobileAPI from '../../helpers/MobileAPI/MobileAPI';
import ConfigManager from '../../helpers/ConfigManager/ConfigManager';
import { useUserInfo } from '../../helpers/useUserInfo';
import { useIsMounted } from '../../helpers/useIsMounted';
import { useUserInfoSubject } from '../../helpers/useUserInfoSubject';
import configFile from '../../config/config.json';

/* Component imports ----------------------------------- */
import {
  IonContent,
  IonIcon,
  IonLoading,
  IonModal,
} from '@ionic/react';
import { motion } from 'framer-motion';
import {
  arrowBack,
  fingerPrintOutline,
  sparklesOutline,
} from 'ionicons/icons';
import CSButton from '../CSButton/CSButton';

/* Type imports ---------------------------------------- */
import type { LoginRequestReturnType } from '../../helpers/MobileAPI/login/LoginRequestReturnType';
import type { CSUserInfo } from '../../helpers/MobileAPI/user/CSUserInfo';
import type { CSFetchUserAddTokenReturnType } from '../../helpers/MobileAPI/user/CSFetchUserAddTokenReturnType';

/* Style imports --------------------------------------- */
import './LoginModal.css';

/* Internal variables ---------------------------------- */
const container = {
  hidden: { opacity: 0, duration: 0.5 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.1,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const item2 = {
  hidden: { opacity: 0, scale: 1.2 },
  visible: {
    scale: 0.7,
    opacity: 1,
  },
};

/* Form error type declaration ------------------------- */
interface FormFieldError {
  error: boolean;
  errorText?: string;
}

/* LoginModal component prop types ---------------------- */
interface LoginPageProps {
  isOpen: boolean;
  setIsOpen: (arg0: boolean) => void;
  setAuthToken: (pNewAuthToken: string) => void;
}

/* LoginModal component --------------------------------- */
const LoginModal: React.FC<LoginPageProps> = ({ isOpen, setIsOpen, setAuthToken }) => {
  const isMounted = useIsMounted();

  const [ loggingIn, setLoggingIn ] = useState(false);
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ formError, setFormError ] = useState<Record<string, FormFieldError>>(
    {
      email: {
        error: false,
        errorText: undefined,
      },
      password: {
        error: false,
        errorText: undefined,
      },
    },
  );
  const { getUserInfo } = useUserInfo();
  const [ userInfo, updateUserInfo ] = useUserInfoSubject();

  useEffect(
    () => {
      StatusBar.setStyle(
        {
          style: isOpen ? Style.Light : Style.Dark,
        },
      )
        .catch(
          (pException) => {
            console.error(`[ERROR] <LoginModal> Failed to set the status bar to a ${isOpen ? 'dark' : 'light'} style :`, pException);
          },
        );
    },
    [ isOpen ],
  );

  const validateFields = (pTargetName: string, pValue: string): boolean => {
    switch(pTargetName) {
      case 'cs-email':
        if(!RegExLibrary.email.test(pValue)) {
          setFormError(
            {
              ...formError,
              email: {
                error: true,
                errorText: 'Email non valide.',
              },
            },
          );
          return false;
        } else {
          setFormError(
            {
              ...formError,
              email: {
                error: false,
                errorText: '',
              },
            },
          );
          return true;
        }
      case 'cs-pass':
        if(pValue.length < 5) {
          setFormError(
            {
              ...formError,
              password: {
                error: true,
                errorText: 'Mot de passe trop court.',
              },
            },
          );
          return false;
        } else {
          setFormError(
            {
              ...formError,
              password: {
                error: false,
                errorText: '',
              },
            },
          );
          return true;
        }
      default:
        console.error(`[ERROR] <LoginPage.validateFields> Unknown event target ${pTargetName}`);
        throw Error(`[ERROR] <LoginPage.validateFields> Unknown event target ${pTargetName}`);
    }
  };

  const onEmailChange: React.ChangeEventHandler<HTMLInputElement> = (pEvent) => {
    const lFieldName = pEvent.target.name;
    const lNewValue = pEvent.target.value;

    validateFields(lFieldName, lNewValue);

    setEmail(lNewValue);
  };

  const onPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (pEvent) => {
    const lFieldName = pEvent.target.name;
    const lNewValue = pEvent.target.value;

    validateFields(lFieldName, lNewValue);

    setPassword(lNewValue);
  };

  const onLogin: React.MouseEventHandler<HTMLButtonElement> = (pEvent) => {
    pEvent.preventDefault();

    if(email === '') {
      /* Email is empty */
      setFormError(
        (pPreviousState) => {
          return {
            ...pPreviousState,
            email: {
              error: true,
              errorText: 'Veuillez rentrer votre adresse email.',
            },
          };
        },
      );
    }

    if(password === '') {
      /* Password is empty */
      setFormError(
        (pPreviousState) => {
          return {
            ...pPreviousState,
            password: {
              error: true,
              errorText: 'Veuillez rentrer votre mot de passe.',
            },
          };
        },
      );
    }

    if(email === '' && password === '') {
      /* Both are empty */
      toast.warning(
        `⚠️ Veuillez rentrer vos identifiants.`,
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
        },
      );
    }

    if(email === '' || password === '') {
      /* At least one of them are empty */
      return; /* Don't try to log in */
    }

    setLoggingIn(true);

    return MobileAPI.login(email, password)
      .then(
        async (pResult: LoginRequestReturnType) => {
          if(pResult === undefined || pResult === null) {
            toast.error(
              `⚠️ Erreur de connexion : pas d'information en retour.`,
              {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: false,
                draggable: true,
                progress: undefined,
              },
            );
          } else if(pResult.statusCode === 401 || pResult.statusCode === 403) {
            toast.error(
              `⚠️ Identifiant ou mot de passe incorrect.`,
              {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: false,
                draggable: true,
                progress: undefined,
              },
            );
          } else if(pResult.authToken === '') {
            toast.error(
              `⚠️ Erreur de connexion : pas de token d'authentification.`,
              {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: false,
                draggable: true,
                progress: undefined,
              },
            );
          } else if(pResult.statusCode >= 200 && pResult.statusCode < 300) {
            toast.success(
              '👍 Connexion réussie !',
              {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: false,
                draggable: true,
                progress: undefined,
              },
            );

            /* Save the token */
            setAuthToken(pResult.authToken);

            /* Load user info */
            try {
              // Get user information
              const lUserInfo: CSUserInfo | undefined = await getUserInfo();
              if(lUserInfo !== undefined) {
                // Check if user already have token registered
                const lHasToken = lUserInfo.push_tokens?.find(
                  (pToken) => {
                    return pToken.name === window.localStorage.getItem('push_token');
                  },
                );

                if(!lHasToken) {
                  // Register user's new push token
                  // eslint-disable-next-line promise/no-nesting
                  MobileAPI.fetchUserAddToken(window.localStorage.getItem('push_token') as string)
                    .then(
                      async (pResult: CSFetchUserAddTokenReturnType) => {
                        // console.log(`[DEBUG] <LoginPage> MobileAPI.fetchUserAddToken success :`, pResult);

                        // Fetch again to get up to date push_tokens list
                        const lNewUserInfo: CSUserInfo | undefined = await getUserInfo();

                        // Update user info with up to date data
                        if(lNewUserInfo !== undefined) {
                          updateUserInfo(lNewUserInfo);
                        }
                      },
                    )
                    .catch(
                      (pException) => {
                        console.error(`[ERROR] <LoginPage.onLogin> MobileAPI.fetchUserAddToken failed :`, pException);
                      },
                    );
                } else {
                  updateUserInfo(lUserInfo);
                }
              }
            }
            catch(pException) {
              toast.error(
                `⚠️ Une erreur s'est produite en récupérant les informations de votre compte.`,
                {
                  position: 'top-center',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                },
              );
            }
            setEmail('');
            setPassword('');
            setIsOpen(false);
          } else {
            toast.error(
              pResult.statusText !== undefined ? `⚠️ Erreur de connexion : ${pResult.statusText}.` : `⚠️ Erreur de connexion.`,
              {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: false,
                draggable: true,
                progress: undefined,
              },
            );
          }
        },
      )
      .catch(
        (pException) => {
          toast.error(
            '⚠️ Erreur de réseau.',
            {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              pauseOnFocusLoss: false,
              draggable: true,
              progress: undefined,
            },
          );
          console.error(`[ERROR] <LoginPage.onLogin> MobileAPI.login failed :`, pException);
          // throw new Error(`[ERROR] <LoginModal.onLogin> MobileAPI.login failed.`);
        },
      )
      .finally(
        () => {
          isMounted && setLoggingIn(false);
        },
      );
  };

  const isSubmitDisabled = (): boolean => {
    let lDisabled: boolean = false;

    if(loggingIn) {
      lDisabled = lDisabled || true;
    }

    if(formError.email.error) {
      lDisabled = lDisabled || true;
    }

    if(formError.password.error) {
      lDisabled = lDisabled || true;
    }

    return lDisabled;
  };

  return (
    <IonModal
      isOpen={isOpen}
      onWillDismiss={() => isOpen && setIsOpen(false)}
      cssClass="fullscreen-modal"
    >
      <IonContent
        fullscreen
        scrollY={false}
      >
        <button
          className="modal-back-button"
          onClick={() => setIsOpen(false)}
        >
          <IonIcon icon={arrowBack} />
        </button>
        <motion.div
          variants={container}
          initial="hidden"
          animate="visible"
        >
          <div className="modal-cs-icon">
            <motion.img
              variants={item2}
              src="/assets/logos/bloomdayz-full-logo-transparent.svg"
            />
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path
              fill="#FFF8EB"
              fillOpacity="1"
              d="M0,192L30,186.7C60,181,120,171,180,149.3C240,128,300,96,360,122.7C420,149,480,235,540,245.3C600,256,660,192,720,165.3C780,139,840,149,900,165.3C960,181,1020,203,1080,202.7C1140,203,1200,181,1260,144C1320,107,1380,53,1410,26.7L1440,0L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"
            />
          </svg>
          <motion.div
            variants={item}
            className="login-header"
          >
            Accéder à mon compte
          </motion.div>
          <motion.div
            variants={item}
            className="login-subheader"
            style={
              {
                paddingTop: 5,
                fontSize: '25px',
              }
            }
          >
            🔑
          </motion.div>
          <motion.div
            variants={item}
            className="login-subheader login-subheader-centered"
          >
            Veuillez vous connecter avec les identifiants de votre compte Bloomdayz
          </motion.div>
          <div style={{ padding: '15px 15px' }}>
            <form>
              <motion.div
                variants={item}
                style={
                  {
                    position: 'relative',
                  }
                }
              >
                <div className="Input-icon">
                  <IonIcon
                    style={{ marginTop: 4, marginRight: 12 }}
                    icon={fingerPrintOutline}
                  />
                </div>
                <input
                  className="Input-text"
                  name="cs-email"
                  type="email"
                  placeholder="Votre adresse mail"
                  onChange={onEmailChange}
                />
                {
                  (formError.email.error) &&
                    <p className="Input-error-info-text">
                      {
                        (formError.email.errorText !== '' && formError.email.errorText !== undefined) ?
                          formError.email.errorText :
                          `Erreur inconnue dans l'adresse email`
                      }
                    </p>
                }
              </motion.div>
              <motion.div
                variants={item}
                style={
                  {
                    position: 'relative',
                  }
                }
              >
                <div className="Input-icon">
                  <IonIcon
                    style={{ marginTop: 4, marginRight: 12 }}
                    icon={sparklesOutline}
                  />
                </div>
                <input
                  className="Input-text"
                  name="cs-pass"
                  type="password"
                  placeholder="Votre mot de passe"
                  onChange={onPasswordChange}
                />
                {
                  (formError.password.error) &&
                    <p className="Input-error-info-text">
                      {
                        (formError.password.errorText !== '' && formError.password.errorText !== undefined) ?
                          formError.password.errorText :
                          `Erreur inconnue dans le mot de passe`
                      }
                    </p>
                }
              </motion.div>
              <motion.div variants={item}>
                <CSButton
                  type="submit"
                  onClick={onLogin}
                  disabled={isSubmitDisabled()}
                >
                  Se connecter
                </CSButton>
              </motion.div>
              {/* WARN : Removed because the App Store review refused to have a link to an external site to create an account */}
              {/* <motion.div
                variants={item}
                className="register-link"
              >
                <a href={`${ConfigManager.getAPIURL()}/inscription`}>
                  {'Pas de compte ? Créer un compte'}
                </a>
              </motion.div> */}
            </form>
            <motion.div variants={item}>
              <div className="version-footer">
                <a
                  href={`${configFile.apiEndpoint}/privacy-mobile`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {`Politique de confidentialité`}
                </a>
              </div>
            </motion.div>
            <motion.div variants={item}>
              <div className="version-footer">
                {`Version : ${ConfigManager.getAppVersion()}`}
              </div>
            </motion.div>
          </div>
        </motion.div>
        <IonLoading
          isOpen={loggingIn}
          message="Connexion..."
        />
      </IonContent>
    </IonModal>
  );
};

/* Export LoginModal component -------------------------- */
export default LoginModal;
