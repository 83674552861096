/* Module imports -------------------------------------- */
import { csFetch } from '../fetch/fetch';

/* Type imports ---------------------------------------- */
import type { FetchResponse } from '../fetch/FetchResponse';
import type { ReferenceReturnType } from './ReferenceReturnType';
import { HTTPMethods } from '../../../types/HTTPMethods';
import type { CSReferenceBodyType} from './CSReferenceBodyType';

/* fetchImage function --------------------------------- */
export const fetchReference = async (
  webId: string,
): Promise<ReferenceReturnType> => {
  const lReturnObj: ReferenceReturnType = {
    statusCode: 0,
  };

  try {
    const lFetchResponse: FetchResponse = await csFetch(
      encodeURI(`/mobileapi/getreference/${webId}`),
      HTTPMethods.GET,
    );

    lReturnObj.statusCode = lFetchResponse.statusCode;
    lReturnObj.statusText = lFetchResponse.statusText;

    // console.log(`[DEBUG] <MobileAPI.fetchReference> Request succeeded, got response :`, lFetchResponse);

    if(lFetchResponse.body !== undefined) {
      const data: CSReferenceBodyType = lFetchResponse.body as unknown as CSReferenceBodyType;
      lReturnObj.reference = data.reference;
    }
  } catch(pException) {
    console.error(`[ERROR] <MobileAPI.fetchReference> GET request failed :`, pException);
    throw new Error(`[ERROR] <MobileAPI.fetchReference> GET request failed.`);
  }

  return lReturnObj;
};
