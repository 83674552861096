/* Framework imports ----------------------------------- */
import {
  useEffect,
  useState,
} from 'react';

/* Type imports ---------------------------------------- */
import { CSUserInfo } from './MobileAPI/user/CSUserInfo';

/* Module imports -------------------------------------- */
import userInfoSubject from './Subjects/userInfoSubject';

/* useUserInfoSubject hook ----------------------------- */
export const useUserInfoSubject = (): [CSUserInfo | undefined, (newUserInfo: CSUserInfo | undefined) => void] => {
  const [ userInfo, setUserInfo ] = useState<CSUserInfo | undefined>(undefined);

  const updateUserInfo = (newUserInfo: CSUserInfo | undefined) => {
    userInfoSubject.next(newUserInfo);
  };

  useEffect(() => {
    userInfoSubject.subscribe({
      next: (v) => setUserInfo(v),
    });
  }, []);

  return [
    userInfo,
    updateUserInfo,
  ];
};
