/* Framework imports ----------------------------------- */
import React from 'react';

/* Component imports ----------------------------------- */

/* Style imports --------------------------------------- */
import './CSAccordionItem.css';

/* CSAccordionItem component prop types ---------------- */
interface CSAccordionItemProps {
  isActive: boolean;
  setIsActive: (pNewState: boolean) => void;
  title: string;
  contents: React.ReactNode;
}

/* CSAccordionItem component --------------------------- */
const CSAccordionItem: React.FC<CSAccordionItemProps> = (
  {
    isActive,
    setIsActive,
    title,
    contents,
  },
) => {
  const onTitleClicked: React.MouseEventHandler<HTMLDivElement> = (pEvent) => {
    setIsActive(!isActive);
  };

  return (
    <div className="accordion-item">
      <div
        className="accordion-title"
        onClick={onTitleClicked}
      >
        <div>
          {title}
        </div>
        <div>
          {
            isActive ?
              `-` :
              `+`
          }
        </div>
      </div>
      {
        isActive &&
          <div className="accordion-content">
            {contents}
          </div>
      }
    </div>
  );
};

/* Export CSAccordionItem component -------------------- */
export default CSAccordionItem;
