/* Framework imports ----------------------------------- */
import React from 'react';

/* Type imports ---------------------------------------- */
import type { News } from '../../types/News';

/* Component imports ----------------------------------- */
import { motion } from 'framer-motion';
import CSFeedCard from '../CSFeedCard/CSFeedCard';
import CSFeedCardSkeleton from './CSFeedCardSkeleton';

/* Internal variables ---------------------------------- */
const container = {
  hidden: { opacity: 0, duration: 0.5 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.5,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

/* CSFeed component prop types ------------------------- */
interface CSFeedProps {
  news: News[];
  onImageClick: React.MouseEventHandler<HTMLImageElement>;
  loading: boolean;
}

/* CSFeed component ------------------------------------ */
const CSFeed: React.FC<CSFeedProps> = ({ news, onImageClick, loading }) => {
  if(news.length === 0 && !loading) {
    return (
      <motion.div
        variants={container}
        initial="hidden"
        animate="visible"
      >
        <div className="EndOfFeedDiv">
          <motion.div variants={item}>
            <h1>
              😕
            </h1>
          </motion.div>
          <motion.div variants={item}>
            <p>
              <b>
                Pas d'actualité disponible pour le moment
              </b>
          &nbsp;
              <br />
              <br />
              <span>
                Repassez dans quelques jours pour les dernières nouvelles ✌️
                &nbsp;
              </span>
            </p>
          </motion.div>
        </div>
      </motion.div>
    );
  }

  if(news.length === 0 && loading) {
    return (
      <motion.div
        variants={container}
        initial="hidden"
        animate="visible"
      >
        <CSFeedCardSkeleton />
        <CSFeedCardSkeleton />
      </motion.div>
    );
  }

  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="visible"
    >
      {
        news
          .filter(
            (pPost) => {
              if(
                pPost.description.replace(/<[^>]*>?/gm, '').length > 0 ||
                pPost.images.length > 0
              ) {
                return true;
              } else {
                console.warn(`[WARN ] <CSFeed> News "${pPost.title}" (${pPost.id}) has no description or images, hiding it.`);
                return false;
              }
            },
          )
          .map(
            (pPost: News) => {
              return (
                <motion.div
                  variants={item}
                  key={`key-${pPost.id}`}
                >
                  <CSFeedCard
                    news={pPost}
                    onImageClick={onImageClick}
                  />
                </motion.div>
              );
            },
          )
      }
    </motion.div>
  );
};

/* Export CSFeed component ----------------------------- */
export default CSFeed;
