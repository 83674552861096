/* Framework imports ----------------------------------- */
import React, {
  createRef,
  useEffect,
  useRef,
  useState,
} from 'react';

/* Module imports -------------------------------------- */
import { formatDistance } from 'date-fns';
import { fr } from 'date-fns/locale';
import { formatDateStrForMobile } from '../../utils/formatDate';

/* Type imports ---------------------------------------- */
import type {
  News,
  NewsImage,
} from '../../types/News';

/* Component imports ----------------------------------- */
import {
  IonSlides,
  IonSlide,
  IonIcon,
} from '@ionic/react';
import {
  removeOutline,
  timeOutline,
} from 'ionicons/icons';
import SanitizeHTML from '../SanitizeHTML/SanitizeHTML';

/* Styling imports ------------------------------------- */
import './CSFeedCard.css';

/* CSFeedCard component prop types --------------------- */
interface CSFeedCardProps {
  news: News;
  onImageClick: React.MouseEventHandler<HTMLImageElement>;
}

/* Type definition ------------------------------------- */
interface NewsBooleanList {
  [id: string]: boolean;
}

interface OverlayRefList {
  [id: string]: React.Ref<HTMLDivElement>;
}

/* CSFeedCard component -------------------------------- */
const CSFeedCard: React.FC<CSFeedCardProps> = ({ news, onImageClick }) => {
  const [ loadingStatus, setLoadingStatus ] = useState<NewsBooleanList>(
    news.images.reduce(
      (pAcc, pCurrent, pIndex) => {
        return {
          ...pAcc,
          [pIndex.toString()]: false,
        };
      },
      {},
    ),
  );
  const [ overlayRefs, setOverlayRefs ] = useState<OverlayRefList>({});
  const [ activeTab, setActiveTab ] = useState<number>(0);
  const sliderRef = useRef<HTMLIonSlidesElement>(null);

  useEffect(
    () => {
      if(sliderRef !== null) {
        sliderRef.current?.lockSwipes(news.images.length < 2)
          .catch(
            (pException) => {
              console.error(`[ERROR] <CSFeedCard> Failed to ${news.images.length < 2 ? '' : 'un'}lock swipes :`, pException);
            },
          );

        sliderRef.current?.lockSwipeToPrev(activeTab <= 0)
          .catch(
            (pException) => {
              console.error(`[ERROR] <CSFeedCard> Failed to ${activeTab === 0 ? '' : 'un'}lock swipe to previous image :`, pException);
            },
          );

        sliderRef.current?.lockSwipeToNext(activeTab >= news.images.length - 1)
          .catch(
            (pException) => {
              console.error(`[ERROR] <CSFeedCard> Failed to ${activeTab === 0 ? '' : 'un'}lock swipe to next image :`, pException);
            },
          );
      }
    },
    [
      activeTab,
      sliderRef,
      news.images.length,
    ],
  );

  useEffect(
    () => {
      setOverlayRefs(
        news.images.reduce(
          (pAcc, pCurrent, pIndex) => {
            return {
              ...pAcc,
              [pIndex.toString()]: createRef(),
            };
          },
          {},
        ),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // Handle onLoad image event to update loading status
  const imageLoadedHandler = (pIndex: number): React.ReactEventHandler<HTMLImageElement> => (pEvent) => {
    setLoadingStatus(
      {
        ...loadingStatus,
        [pIndex]: true,
      },
    );

    const overlayRef = overlayRefs[pIndex];

    if(overlayRef !== undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const element = (overlayRef.current as HTMLDivElement);

      const baseClassName = element.className;

      element.className = `${baseClassName} opacity`;

      setTimeout(
        () => {
          element.className = `${baseClassName} hide-element`;
        },
        600,
      );
    }
  };

  // Handle slide changes by swipe to update activeTab
  const sliderTabChangeHandler = async (pEvent: CustomEvent<void>) => {
    try {
      const lActiveIndex = await (pEvent.target as HTMLIonSlidesElement).getActiveIndex();
      setActiveTab(lActiveIndex);
    } catch(pException) {
      console.error(`[ERROR] <CSFeedCard> sliderTabChangeHandler failed :`, pException);
    }

  };

  const getImageStyle = (pIndex: number): React.CSSProperties => {
    return !loadingStatus[pIndex] ? { display: 'none' } : {};
  };

  const onImageClicked = (pIndex: number): React.MouseEventHandler<HTMLImageElement> => (pEvent) => {
    if(loadingStatus[pIndex]) {
      onImageClick(pEvent);
    }
  };

  return (
    <div className="csfeed-card">
      {
        news.stageIds.length > 0 &&
          <div className="csfeed-card-header">
            🏆
            {' '}
            {`Stage de votre enfant : ${news.title}`}
          </div>
      }
      {
        news.images.length > 0 && (
          <div className="csfeed-card-gallery">
            <IonSlides
              className="csfeed-card-gallery-slider"
              tabIndex={activeTab}
              onIonSlideWillChange={sliderTabChangeHandler}
              ref={sliderRef}
              options={
                {
                  zoom: true,
                }
              }
              style={
                news.stageIds.length < 1 ?
                  {
                    borderRadius: '.5rem .5rem 0 0',
                  } :
                  undefined
              }
            >
              {
                news.images.map(
                  (pNews: NewsImage, pIndex: number) => {
                    if(
                      typeof pNews.description === 'string' &&
                      pNews.description.toLowerCase() === 'false'
                    ) {
                      pNews.description = false;
                    }

                    return (
                      <IonSlide key={`slide-key-${pNews.id}`}>
                        <div className="csfeed-card-gallery-content blur">
                          <img
                            alt={`Asset number ${pIndex} for this post`}
                            className="csfeed-card-gallery-image"
                            src={pNews.source}
                            onLoad={imageLoadedHandler(pIndex)}
                            onClick={onImageClicked(pIndex)}
                            style={getImageStyle(pIndex)}
                          />
                          {
                            pNews.description !== false &&
                            pNews.description.length > 0 &&
                              <div className="csfeed-card-gallery-image-description">
                                <SanitizeHTML html={pNews.description} />
                              </div>
                          }
                        </div>
                        <div
                          className="overlay"
                          ref={overlayRefs[pIndex]}
                        >
                          <div className="overlay__inner">
                            <div className="overlay__content">
                              <span className="spinner" />
                            </div>
                          </div>
                        </div>
                      </IonSlide>
                    );
                  },
                )
              }
            </IonSlides>
          </div>
        )
      }
      {
        news.images.length > 1 &&
          <div className="csfeed-card-toolbar">
            <div className="csfeed-card-toolbar-center">
              {
                news.images.map(
                  (pNewsItem: NewsImage, pIndex: number) => {
                    return (
                      <IonIcon
                        key={`news-image-slide-indicator-${pNewsItem.id}`}
                        icon={removeOutline}
                        style={
                          {
                            color: pIndex === activeTab ? '#D96B45' : 'grey',
                          }
                        }
                      />
                    );
                  },
                )
              }
            </div>
          </div>
      }
      <div className="csfeed-card-content">
        <SanitizeHTML html={news.description} />
        <br />
        <div className="csfeed-card-age">
          <IonIcon
            className="csfeed-card-age-icon"
            icon={timeOutline}
          />
          &nbsp;&nbsp;Publié&nbsp;
          {
            formatDistance(
              Date.parse(formatDateStrForMobile(news.date)),
              Date.now(),
              {
                addSuffix: true,
                locale: fr,
              },
            ).toLowerCase()
          }
        </div>
      </div>
    </div>
  );
};

/* Export CSFeedCard component ------------------------- */
export default CSFeedCard;
