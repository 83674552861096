/* Framework imports ----------------------------------- */
import React from 'react';

/* Component imports ----------------------------------- */
import sanitizeHtml from 'sanitize-html';

/* Internal variables ---------------------------------- */
const defaultOptions: sanitizeHtml.IOptions = {
  allowedTags: [
    'b',
    'i',
    'em',
    'strong',
    'a',
    'p',
    'br',
  ],
  allowedAttributes: {
    'a': [ 'href' ],
  },
  allowedIframeHostnames: [ 'www.youtube.com' ],
};

/* Internal functions ---------------------------------- */
const sanitize = (pInputStr: string, pOptions: sanitizeHtml.IOptions | undefined) => (
  {
    __html: sanitizeHtml(
      pInputStr,
      {
        ...defaultOptions,
        ...pOptions,
      },
    ),
  }
);

/* Type definition ------------------------------------- */
interface SanitizeHTMLProps {
  html: string;
  options?: sanitizeHtml.IOptions;
}

/* SanitizeHTML component ------------------------------ */
const SanitizeHTML: React.FC<SanitizeHTMLProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({ html, options, ...props }) => (
  <div
    dangerouslySetInnerHTML={sanitize(html, options)}
    {...props}
  />
);

/* Export SanitizeHTML component ----------------------- */
export default SanitizeHTML;
