/* Module imports -------------------------------------- */
import { csFetch } from '../fetch/fetch';

/* Type imports ---------------------------------------- */
import type { FetchResponse } from '../fetch/FetchResponse';
import type { LoginRequestReturnType } from './LoginRequestReturnType';
import { HTTPMethods } from '../../../types/HTTPMethods';

/* login function -------------------------------------- */
export const login = async (pEmailAddr: string, pPassword: string): Promise<LoginRequestReturnType> => {
  const lReturnObj: LoginRequestReturnType = {
    authToken: '',
    statusCode: 0,
  };

  const lRequestBody = {
    email: pEmailAddr,
    password: pPassword,
  };

  try {
    const lFetchResponse: FetchResponse = await csFetch(
      '/mobileapi/authenticate',
      HTTPMethods.POST,
      lRequestBody,
    );

    lReturnObj.statusCode = lFetchResponse.statusCode;
    lReturnObj.statusText = lFetchResponse.statusText;

    // console.log(`[DEBUG] <MobileAPI.login> Request succeeded, got response :`, lFetchResponse);

    if(lFetchResponse.body?.token !== undefined) {
      const lToken = lFetchResponse.body?.token as string;
      // console.log(`[DEBUG] <MobileAPI.login> POST request succeeded, got token :`, lToken);

      lReturnObj.authToken = lToken;
    }
  } catch(pException) {
    console.error(`[ERROR] <MobileAPI.login> POST request failed :`, pException);
    throw new Error(`[ERROR] <MobileAPI.login> POST request failed.`);
  }

  return lReturnObj;
};
