/* Framework imports ----------------------------------- */
import { toast } from 'react-toastify';

/* Type imports ---------------------------------------- */
import MobileAPI from './MobileAPI/MobileAPI';
import { CSUserInfo } from './MobileAPI/user/CSUserInfo';
import { CSFetchUserInfoReturnType } from './MobileAPI/user/CSFetchUserInfoReturnType';

/* Type definitions ------------------------------------ */
interface UseUserInfoReturnType {
  getUserInfo: () => Promise<CSUserInfo|undefined>;
}

/* useUserInfo hook -------------------------------- */
export const useUserInfo = (): UseUserInfoReturnType => {
  const getUserInfo = async (): Promise<CSUserInfo|undefined> => {
    try {
      const lRequestResults: CSFetchUserInfoReturnType = await MobileAPI.fetchUserInfo();
      if(lRequestResults.statusCode < 300 && lRequestResults.statusCode > 199) {
        return lRequestResults.userInfo;
      } else {
        toast.error(
          `⚠️ Une erreur s'est produite en récupérant les informations de votre compte.`,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            pauseOnFocusLoss: false,
            draggable: true,
            progress: undefined,
          },
        );

        return undefined;
      }
    } catch(pException) {
      console.error(`[ERROR] <SettingsPage> Failed to fetch the user's information :`, pException);
      toast.error(
        `⚠️ Une erreur s'est produite en récupérant les informations de votre compte.`,
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
        },
      );
    }
  };

  return {
    getUserInfo,
  };
};
