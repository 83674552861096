/* Framework imports ----------------------------------- */
import React from 'react';

/* Library imports ------------------------------------- */
import {
  TransformWrapper,
  TransformComponent,
} from 'react-zoom-pan-pinch';

/* Styling imports ------------------------------------- */
import './CSFeedCardModal.css';

/* CSFeedCardModal component prop types ---------------- */
interface CSFeedCardModalProps {
  source: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

/* CSFeedCardModal component --------------------------- */
const CSFeedCardModal: React.FC<CSFeedCardModalProps> = ({ source, onClick }) => {
  return (
    <div
      className="cs-feed-card-modal"
      onClick={onClick}
    >
      <TransformWrapper>
        <TransformComponent>
          <img
            alt=""
            src={source}
            className="cs-feed-card-modal-image"
          />
        </TransformComponent>
      </TransformWrapper>
    </div>
  );
};

/* Export CSFeedCardModal component -------------------- */
export default CSFeedCardModal;
