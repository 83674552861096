/* Framework imports ----------------------------------- */
import {
  useEffect,
  useState,
} from 'react';

/* useLocalStorage hook -------------------------------- */
export const useLocalStorage = (pKey: string, pInitialValue: string = ''): [string, (pNewAuthToken: string) => void] => {
  /* eslint-disable @typescript-eslint/strict-boolean-expressions */
  const lInitialValue: string = localStorage.getItem(pKey) || pInitialValue;

  const [ val, setValNonPersistent ] = useState(lInitialValue);

  const setVal = (pNewAuthToken: string) => {
    /* Save it for persistence */
    window.localStorage.setItem(pKey, pNewAuthToken);

    /* Save it just for the React state */
    setValNonPersistent(pNewAuthToken);
  };

  useEffect(
    () => {
      const lHandler = (): void => {
        const lCurrentStoredValue: string | null = localStorage.getItem(pKey);
        if(lCurrentStoredValue === null) {
          /* Reset the current state value */
          setVal('');
        } else if(val !== lCurrentStoredValue) {
          /* Updated the current state value */
          setVal(lCurrentStoredValue);
        }
      };

      window.addEventListener(
        'storage',
        lHandler,
      );

      return () => {
        /* Remove the event handler to avoid calling state setters when the component is unmounted */
        window.removeEventListener(
          'storage',
          lHandler,
        );
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [], /* Runs only once, when the component is mounted */
  );

  useEffect(
    () => {
      setVal(localStorage.getItem(pKey) || '');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ pKey ],
  );

  return [ val, setVal ];
  /* eslint-enable @typescript-eslint/strict-boolean-expressions */
};
