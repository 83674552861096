/* Framework imports ----------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react';

/* Module imports -------------------------------------- */
import { toast } from 'react-toastify';
import MobileAPI from '../../helpers/MobileAPI/MobileAPI';
import { useNewsRegistry } from '../../helpers/useNewsRegistry';

/* Component imports ----------------------------------- */
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import { motion } from 'framer-motion';
import CSFeed from '../../components/CSFeed/CSFeed';
import CSFeedCardModal from '../../components/CSFeedCardModal/CSFeedCardModal';

/* Type imports ---------------------------------------- */
import type { RefresherEventDetail } from '@ionic/core';

/* Styling imports ------------------------------------- */
import './MainFeedPage.css';

/* Internal variables ---------------------------------- */
const container = {
  hidden: { opacity: 0, duration: 0.5 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.5,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

/* MainFeedPage component prop types ------------------- */
interface MainFeedPageProps {
  authToken: string;
}

/* MainFeedPage component ------------------------------ */
const MainFeedPage: React.FC<MainFeedPageProps> = ({ authToken }) => {
  let componentIsMounted = true; /* The component is mounted */

  const { news, addNews, clearNews } = useNewsRegistry();
  const [ loadedAmount, setLoadedAmount ] = useState<number>(0);
  const [ hasLoadedEverything, setHasLoadedEverything ] = useState<boolean>(false);
  const [ fullScreenImageURL, setFullScreenImageURL ] = useState<string>('');
  const [ isLoading, setIsLoading ] = useState<boolean>(false);

  const loadData = async (pReset: boolean = false): Promise<void> => {
    setIsLoading(true);
    try {
      // eslint-disable-next-line camelcase
      const { posts, posts_images } = await MobileAPI.fetchFeed(
        {
          offset: pReset ?
            0 :
            loadedAmount,
        },
      );

      console.log(news);

      if(componentIsMounted) {
        addNews(posts, posts_images, pReset);
        setHasLoadedEverything(!posts.length);
        setLoadedAmount(loadedAmount + posts.length);
      }
    } catch(pException) {
      console.error(`[ERROR] <MainFeedPage> Failed to fetch news feed :`, pException);
      throw pException;
    } finally {
      setIsLoading(false);
    }
  };

  const onScroll = async (pEvent: CustomEvent<void>): Promise<void> => {
    try {
      await loadData();

      (pEvent.target as HTMLIonInfiniteScrollElement).complete()
        .catch(
          (pException) => {
            console.error(`[ERROR] <MainFeedPage.onScroll> pEvent.target.complete() failed :`, pException);

          },
        );
    } catch(pException) {
      console.error(`[ERROR] <MainFeedPage.onScroll> loadData() failed :`, pException);
      toast.error(
        `⚠️ Une erreur s'est produite en récupérant le fil d'actualité.`,
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
        },
      );
      (pEvent.target as HTMLIonInfiniteScrollElement).complete()
        .catch(
          (pException) => {
            console.error(`[ERROR] <MainFeedPage.onScroll> pEvent.target.complete() failed :`, pException);

          },
        );
    }
  };

  const onRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    loadData(true)
      .then(
        () => {
          setTimeout(
            () => {
              event.detail.complete();
            },
            500,
          );
        },
      )
      .catch(
        (pException) => {
          console.error(`[ERROR] <MainFeedPage.onRefresh> loadData() failed :`, pException);
          toast.error(
            `⚠️ Une erreur s'est produite en récupérant le fil d'actualité.`,
            {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              pauseOnFocusLoss: false,
              draggable: true,
              progress: undefined,
            },
          );
          event.detail.complete();
        },
      );
  };

  useIonViewDidEnter(
    () => {
      // console.log('[DEBUG] <MainFeedPage> ionViewDidEnter event fired');
    },
  );

  useIonViewDidLeave(
    () => {
      // console.log('[DEBUG] <MainFeedPage> ionViewDidLeave event fired');
      componentIsMounted && clearNews();
    },
  );

  useIonViewWillEnter(
    () => {
      // console.log('[DEBUG] <MainFeedPage> ionViewWillEnter event fired');
      setHasLoadedEverything(false);
      loadData(true)
        .catch(
          (pException) => {
            console.error(`[ERROR] <MainFeedPage> loadData() failed :`, pException);
            toast.error(
              `⚠️ Une erreur s'est produite en récupérant le fil d'actualité.`,
              {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: false,
                draggable: true,
                progress: undefined,
              },
            );
          },
        );
    },
  );

  useIonViewWillLeave(
    () => {
      // console.log('[DEBUG] <MainFeedPage> ionViewWillLeave event fired');
    },
  );

  /* On first component mount */
  useEffect(
    () => {
      loadData(true)
        .catch(
          (pException) => {
            console.error(`[ERROR] <MainFeedPage> loadData() failed :`, pException);
            toast.error(
              `⚠️ Une erreur s'est produite en récupérant le fil d'actualité.`,
              {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: false,
                draggable: true,
                progress: undefined,
              },
            );
          },
        );

      return () => {
        /* This callback is run when the component is unmounted */
        // eslint-disable-next-line react-hooks/exhaustive-deps
        componentIsMounted = false;
      };
    },
    [
      authToken,
    ],
  );

  const onImageClick: React.MouseEventHandler<HTMLImageElement> = (pEvent) => {
    setFullScreenImageURL((pEvent.target as HTMLImageElement).currentSrc);
  };

  const onImageModalClick: React.MouseEventHandler<HTMLDivElement> = (pEvent) => {
    setFullScreenImageURL('');
  };

  // console.log(`[DEBUG] <MainFeedPage> Got posts :`, news);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            Fil d'actualités
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher
          slot="fixed"
          onIonRefresh={onRefresh}
        >
          <IonRefresherContent
            pullingIcon="lines"
            pullingText="Rafraîchir"
            refreshingSpinner="lines"
            refreshingText="Chargement..."
          />
        </IonRefresher>
        <CSFeed
          news={news}
          onImageClick={onImageClick}
          loading={isLoading}
        />
        {
          hasLoadedEverything && news.length > 0 ?
            <motion.div
              variants={container}
              initial="hidden"
              animate="visible"
            >
              <div className="EndOfFeedDiv">
                <motion.div variants={item}>
                  <h1>
                    🥳
                  </h1>
                </motion.div>
                <motion.div variants={item}>
                  <p>
                    <b>
                      Bravo, vous êtes allés au bout de notre fil d'actualités !
                    </b>
                    &nbsp;
                    <br />
                    <br />
                    <span>
                      Repassez dans quelques jours pour les dernières nouvelles ✌️
                      &nbsp;
                    </span>
                  </p>
                </motion.div>
              </div>
            </motion.div> :
            <IonInfiniteScroll
              id="infinite-scroll"
              onIonInfinite={onScroll}
            >
              <IonInfiniteScrollContent
                loadingSpinner="lines"
                loadingText="Chargement..."
              />
            </IonInfiniteScroll>
        }
      </IonContent>
      {
        fullScreenImageURL !== '' &&
          <CSFeedCardModal
            source={fullScreenImageURL}
            onClick={onImageModalClick}
          />
      }
    </IonPage>
  );
};

/* Export MainFeedPage component ----------------------- */
export default MainFeedPage;
