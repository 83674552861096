/* Framework imports ----------------------------------- */
import React, { useState } from 'react';
import {
  matchPath,
  useLocation,
} from 'react-router';
import {
  Redirect,
  Route,
} from 'react-router-dom';

/* Component imports ----------------------------------- */
import {
  IonIcon,
  IonLabel,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonRouterOutlet,
} from '@ionic/react';
import {
  albumsOutline,
  documentTextOutline,
  cogOutline,
  albums,
  documentText,
  cog,
  logInOutline,
} from 'ionicons/icons';
import MainFeedPage from '../../../pages/MainFeedPage/MainFeedPage';
import DocumentsPage from '../../../pages/DocumentsPage/DocumentsPage';
import SettingsPage from '../../../pages/SettingsPage/SettingsPage';
import LoginModal from '../../../components/LoginModal/LoginModal';

/* Helpers imports ------------------------------------- */
import FirstStartModal from '../../../components/FirstStartModal/FirstStartModal';

/* MatchParams type declaration ------------------------ */
interface MatchParams {
  tabName?: string;
}

/* MainNavBar component prop types --------------------- */
interface MainNavBarProps {
  authToken: string;
  setAuthToken: (pNewAuthToken: string) => void;
}

/* MainNavBar component -------------------------------- */
const MainNavBar: React.FC<MainNavBarProps> = ({ authToken, setAuthToken }) => {
  const [ isLoginOpened, setIsLoginOpened ] = useState<boolean>(false);
  const location = useLocation();

  const match = matchPath<MatchParams>(
    location.pathname,
    {
      path: '/:tabName',
    },
  );

  const onTabWillChange = (pEvent: CustomEvent<{ tab: string }>) => {
    pEvent.detail.tab === 'login' && setIsLoginOpened(true);
  };

  return (
    <>
      <IonTabs onIonTabsWillChange={onTabWillChange}>
        <IonRouterOutlet>
          <Route
            exact
            path="/feed"
          >
            <MainFeedPage authToken={authToken} />
          </Route>
          <Route
            exact
            path="/documents"
          >
            <DocumentsPage authToken={authToken} />
          </Route>
          <Route
            exact
            path="/settings"
          >
            <SettingsPage
              authToken={authToken}
              setAuthToken={setAuthToken}
            />
          </Route>
          <Route
            exact
            path="/"
          >
            <Redirect to="/feed" />
          </Route>
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton
            tab="feed"
            href="/feed"
          >
            <IonIcon icon={match?.params.tabName === 'feed' ? albums : albumsOutline} />
            <IonLabel>
              Fil d'actualité
            </IonLabel>
          </IonTabButton>
          <IonTabButton
            tab="documents"
            href="/documents"
          >
            <IonIcon icon={match?.params.tabName === 'documents' ? documentText : documentTextOutline} />
            <IonLabel>
              Documents
            </IonLabel>
          </IonTabButton>
          {
            authToken ?
              <IonTabButton
                tab="settings"
                href="/settings"
              >
                <IonIcon icon={match?.params.tabName === 'settings' ? cog : cogOutline} />
                <IonLabel>
                  Mon compte
                </IonLabel>
              </IonTabButton> :
              <IonTabButton tab="login">
                <IonIcon icon={logInOutline} />
                <IonLabel>
                  Me connecter
                </IonLabel>
              </IonTabButton>
          }

        </IonTabBar>
      </IonTabs>
      <LoginModal
        isOpen={isLoginOpened}
        setIsOpen={setIsLoginOpened}
        setAuthToken={setAuthToken}
      />
      <FirstStartModal />
    </>

  );
};

/* Export MainNavBar component ------------------------- */
export default MainNavBar;
