/* Framework imports ----------------------------------- */
import React from 'react';

/* Component imports ----------------------------------- */
import { IonText } from '@ionic/react';

/* SectionTitle component prop types ------------------- */
interface SectionTitleProps {
  title: string;
  children?: React.ReactNode;
}

/* SectionTitle component ------------------------------ */
const SectionTitle: React.FC<SectionTitleProps> = ({ title, children }) => {
  return (
    <IonText className="main-title">
      <h3 className="custom-section-title">
        {title}
        {children}
      </h3>
    </IonText>
  );
};

/* Export SectionTitle component ----------------------- */
export default SectionTitle;
