/* Framework imports ----------------------------------- */
import React, { useEffect } from 'react';
import {
  StatusBar,
  Style,
} from '@capacitor/status-bar';

/* Module imports -------------------------------------- */
import { Capacitor } from '@capacitor/core';
import { useAuthToken } from '../helpers/useAuthToken';
import { usePushNotifications } from '../helpers/usePushNotifications';
import { useUserInfoSubject } from '../helpers/useUserInfoSubject';
import { useUserInfo } from '../helpers/useUserInfo';
import { useNetwork } from '../helpers/useNetwork';

/* Component imports ----------------------------------- */
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {
  toast,
  ToastContainer,
} from 'react-toastify';
import MainLayout from '../layouts/MainLayout/MainLayout';

import ErrorPage from '../pages/ErrorPage/ErrorPage';

/* Styling imports ------------------------------------- */
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */

import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';

import '@ionic/react/css/text-transformation.css';

import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import 'react-toastify/dist/ReactToastify.css';

/* Theme variables */
import '../theme/theme.css';
import '../theme/variables.css';
/* App css */
import './App.css';

/* App component --------------------------------------- */
const App: React.FC = () => {
  const [ authToken, setAuthToken ] = useAuthToken();
  const [ userInfo, updateUserInfo ] = useUserInfoSubject();
  const { getUserInfo } = useUserInfo();
  const networkStatus = useNetwork();

  usePushNotifications();

  useEffect(
    () => {
      if(authToken !== '') {
      // Load user info at first render if auth token exists (open app while already logged in)
        getUserInfo()
          .then(
            (pUserInfo) => {
              // console.log(`[DEBUG] <SettingsPage> Setting userInfo :`, pUserInfo);
              updateUserInfo(pUserInfo);
            },
          )
          .catch(
            (pError) => {
              console.error(`[ERROR] <SettingsPage> Failed to fetch the user's information :`, pError);
              toast.error(
                `⚠️ Une erreur s'est produite en récupérant les informations de votre compte.`,
                {
                  position: 'top-center',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                },
              );
            },
          );
      }

      StatusBar.setStyle(
        {
          style: Style.Dark,
        },
      )
        .catch(
          (pException) => {
            console.error(`[ERROR] <App> Failed to set the status bar to a dark style :`, pException);
          },
        );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <IonApp>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <IonReactRouter>
        {
          networkStatus && networkStatus?.connected ?
            <MainLayout
              authToken={authToken}
              setAuthToken={setAuthToken}
            /> :
            <ErrorPage />
        }
      </IonReactRouter>
    </IonApp>
  );
};

/* Export App component -------------------------------- */
export default App;
