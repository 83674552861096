/* Module imports -------------------------------------- */
import { csFetch } from '../fetch/fetch';

/* Type imports ---------------------------------------- */
import type { FetchResponse } from '../fetch/FetchResponse';
import type { CSFetchUserInfoReturnType } from './CSFetchUserInfoReturnType';
import type { CSUserInfo } from './CSUserInfo';
import { HTTPMethods } from '../../../types/HTTPMethods';

/* fetchUserInfo function ------------------------------ */
export const fetchUserInfo = async (): Promise<CSFetchUserInfoReturnType> => {
  const lReturnObj: CSFetchUserInfoReturnType = {
    statusCode: 0,
  };

  try {
    const lFetchResponse: FetchResponse = await csFetch(
      '/mobileapi/me',
      HTTPMethods.GET,
    );

    lReturnObj.statusCode = lFetchResponse.statusCode;
    lReturnObj.statusText = lFetchResponse.statusText;

    // console.log(`[DEBUG] <MobileAPI.fetchUserInfo> Request succeeded, got response :`, lFetchResponse);

    if(lFetchResponse.body !== undefined) {
      lReturnObj.userInfo = lFetchResponse.body as unknown as CSUserInfo;
    }
  } catch(pException) {
    console.error(`[ERROR] <MobileAPI.fetchUserInfo> POST request failed :`, pException);
    throw new Error(`[ERROR] <MobileAPI.fetchUserInfo> POST request failed.`);
  }

  return lReturnObj;
};
