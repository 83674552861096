/* Framework imports ----------------------------------- */
import React from 'react';

/* Module imports -------------------------------------- */

/* Component imports ----------------------------------- */
import { motion } from 'framer-motion';
import CSDocumentListItem from './CSDocumentListItem/CSDocumentListItem';

/* Type imports ---------------------------------------- */
import type { CSDocument } from '../../helpers/MobileAPI/documents/CSDocument';

/* Styling imports ------------------------------------- */
import './CSDocumentList.css';

/* Internal variables ---------------------------------- */
const container = {
  hidden: { opacity: 0, duration: 0.5 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0,
      staggerChildren: 0.1,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

/* CSDocumentList component prop types ----------------- */
interface CSDocumentListProps {
  documents: Array<CSDocument>;
}

/* CSDocumentList component ---------------------------- */
const CSDocumentList: React.FC<CSDocumentListProps> = ({ documents }) => {
  return (
    <div className="cs-document-list">
      <motion.div
        variants={container}
        initial="hidden"
        animate="visible"
      >
        {
          documents.map(
            (
              pDocument: CSDocument,
              pIndex: number,
              pArray: Array<CSDocument>,
            ) => {
              return (
                <motion.div
                  key={pDocument.id}
                  variants={item}
                >
                  <CSDocumentListItem document={pDocument} />
                  {
                    pIndex < documents.length - 1 &&
                      <hr className="cs-document-list-separator" />
                  }
                </motion.div>
              );
            },
          )
        }
      </motion.div>
    </div>
  );
};

/* Export CSDocumentList component --------------------- */
export default CSDocumentList;
