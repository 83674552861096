/* Framework imports ----------------------------------- */
import { useEffect } from 'react';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  RegistrationError,
  Token,
} from '@capacitor/push-notifications';
import {
  Device,
  DeviceInfo,
} from '@capacitor/device';

/* Module imports -------------------------------------- */
import { toast } from 'react-toastify';
import { useLocalStorage } from './useLocalStorage';

/* usePushNotifications hook --------------------------- */
export const usePushNotifications = (): void => {
  const [ pushToken, setPushToken ] = useLocalStorage('push_token', '');

  return useEffect(
    () => {
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions()
        .then(
          async (pResult) => {
            if(pResult.receive === 'granted') {
              // Register with Apple / Google to receive push via APNS/FCM
              // eslint-disable-next-line promise/no-nesting
              try {
                const deviceInfo: DeviceInfo = await Device.getInfo();
                const androidEight = deviceInfo.platform === 'android' && parseInt((deviceInfo.osVersion.split('.'))[0]) >= 8;
                if(androidEight) {
                  await PushNotifications.createChannel({ id: 'cs-notification', importance: 5, name: 'cs-notification' });
                }
              } catch(pException) {
                toast.error(
                  `⚠️ Une erreur s'est produite lors de l'enregistrement au service de notifications Android 8 et plus.`,
                  {
                    position: 'top-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    pauseOnFocusLoss: false,
                    draggable: true,
                    progress: undefined,
                  },
                );
                console.error(`[ERROR] <App> PushNotifications.createChannel failed :`, pException);
              }

              // eslint-disable-next-line promise/no-nesting
              PushNotifications.register()
                .catch(
                  (pException) => {
                    toast.error(
                      `⚠️ Une erreur s'est produite lors de l'enregistrement au service de notifications.`,
                      {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        pauseOnFocusLoss: false,
                        draggable: true,
                        progress: undefined,
                      },
                    );
                    console.error(`[ERROR] <App> PushNotifications.register failed :`, pException);
                  },
                );
            } else {
            // Show some error
            }
          },
        )
        .catch(
          (pException) => {
            toast.error(
              `⚠️ Une erreur s'est produite lors de l'autorisation de la reception de notifications.`,
              {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: false,
                draggable: true,
                progress: undefined,
              },
            );
            console.error(`[ERROR] <App> PushNotifications.requestPermissions failed :`, pException);
          },
        )
      ;

      PushNotifications.addListener(
        'registration',
        (pToken: Token) => {
          // console.log(`[DEBUG] <App> PushNotifications.addListener(registration): Push registration success, token: ${pToken.value}`);
          setPushToken(pToken.value);
        },
      )
        .catch(
          (pException) => {
            console.error(`[ERROR] <App> PushNotifications.addListener(registration) failed :`, pException);
          },
        );

      PushNotifications.addListener(
        'registrationError',
        (pError: RegistrationError) => {
          console.error(`[ERROR] <App> PushNotifications.register failed :`, pError);
        },
      )
        .catch(
          (pException) => {
            console.error(`[ERROR] <App> PushNotifications.addListener(registrationError) failed :`, pException);
          },
        );

      PushNotifications.addListener(
        'pushNotificationReceived',
        (pNotification: PushNotificationSchema) => {
          console.log(`[DEBUG] <App> PushNotifications.addListener(pushNotificationReceived): Push received: ${JSON.stringify(pNotification)}`);
        },
      )
        .catch(
          (pException) => {
            console.error(`[ERROR] <App> PushNotifications.addListener(pushNotificationReceived) failed :`, pException);
          },
        );

      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (pNotification: ActionPerformed) => {
          console.log(`[DEBUG] <App> PushNotifications.addListener(pushNotificationActionPerformed): Push action performed: ${JSON.stringify(pNotification)}`);

        },
      )
        .catch(
          (pException) => {
            console.error(`[ERROR] <App> PushNotifications.addListener(pushNotificationActionPerformed) failed :`, pException);
          },
        );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};
