/* Framework imports ----------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router';

/* Module imports -------------------------------------- */
import { toast } from 'react-toastify';
import MobileAPI from '../../helpers/MobileAPI/MobileAPI';
import { useUserInfo } from '../../helpers/useUserInfo';
import { useLocalStorage } from '../../helpers/useLocalStorage';
import { useUserInfoSubject } from '../../helpers/useUserInfoSubject';
import configFile from '../../config/config.json';

/* Component imports ----------------------------------- */
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonIcon,
  IonNote,
  IonRefresher,
  IonRefresherContent,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import { logOutOutline } from 'ionicons/icons';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import AboutModal from '../../components/AboutModal/AboutModal';

/* Type imports ---------------------------------------- */
import type { RefresherEventDetail } from '@ionic/core';
import type { CSFetchUserRemoveTokenReturnType } from '../../helpers/MobileAPI/user/CSFetchUserRemoveTokenReturnType';

/* Styling imports ------------------------------------- */
import './SettingsPage.css';

/* SettingsPage component prop types ------------------- */
interface SettingsPageProps {
  authToken: string;
  setAuthToken: (pNewAuthToken: string) => void;
}

/* SettingsPage component ------------------------------ */
const SettingsPage: React.FC<SettingsPageProps> = ({ authToken, setAuthToken }) => {
  let componentIsMounted = true; /* The component is mounted */

  const history = useHistory();
  const [ userInfo, updateUserInfo ] = useUserInfoSubject();
  const { getUserInfo } = useUserInfo();
  const [ pushToken, setPushToken ] = useLocalStorage('push_token', '');
  const [ isAboutOpened, setIsAboutOpened ] = useState<boolean>(false);

  const loadUserData = async (): Promise<void> => {
    if(authToken !== '') {
      try {
        const lUserInfo = await getUserInfo();
        if(componentIsMounted) {
          console.log(`[DEBUG] <SettingsPage> Setting userInfo :`, lUserInfo);
          updateUserInfo(lUserInfo);
        }
      } catch(pException) {
        console.error(`[ERROR] <SettingsPage> Failed to fetch the user's information :`, pException);
        toast.error(
          `⚠️ Une erreur s'est produite en récupérant les informations de votre compte.`,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            pauseOnFocusLoss: false,
            draggable: true,
            progress: undefined,
          },
        );
        history.push('/feed');
      }
    }
  };

  const onRefresh = (pEvent: CustomEvent<RefresherEventDetail>): void => {
    loadUserData()
      .then(
        () => {
          setTimeout(
            () => {
              pEvent.detail.complete();
            },
            500,
          );
        },
      )
      .catch(
        (pException) => {
          console.error(`[ERROR] <SettingsPage.onRefresh> loadUserData() failed :`, pException);
        },
      );
  };

  useIonViewDidEnter(
    () => {
      // console.log('[DEBUG] <SettingsPage> ionViewDidEnter event fired');
    },
  );

  useIonViewDidLeave(
    () => {
      // console.log('[DEBUG] <SettingsPage> ionViewDidLeave event fired');
    },
  );

  useIonViewWillEnter(
    () => {
      // console.log('[DEBUG] <SettingsPage> ionViewWillEnter event fired');
      loadUserData()
        .catch(
          (pException) => {
            console.error(`[ERROR] <SettingsPage> loadUserData() failed :`, pException);
          },
        );
    },
  );

  useIonViewWillLeave(
    () => {
      // console.log('[DEBUG] <SettingsPage> ionViewWillLeave event fired');
    },
  );

  useEffect(
    () => {
      loadUserData()
        .catch(
          (pException) => {
            console.error(`[ERROR] <SettingsPage> loadUserData() failed :`, pException);
          },
        );

      return () => {
        /* This callback is run when the component is unmounted */
        // eslint-disable-next-line react-hooks/exhaustive-deps
        componentIsMounted = false;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ authToken ],
  );

  const onLogoutClick: React.MouseEventHandler<HTMLIonItemElement> = (pEvent) => {
    if(userInfo) {
      // console.log(`[DEBUG] <onLogoutLoginClick> pushToken =`, pushToken);
      const lToken = userInfo.push_tokens?.find((token) => token.name === pushToken);
      if(lToken) {
        MobileAPI.fetchUserRemoveToken(lToken.id )
          .then(
            (pValue: CSFetchUserRemoveTokenReturnType) => {
              if(pValue.result === 'success') {
                toast.success(
                  `Vous avez été déconnecté(e) avec succès.`,
                  {
                    position: 'top-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    pauseOnFocusLoss: false,
                    draggable: true,
                    progress: undefined,
                  },
                );
                setAuthToken('');
                updateUserInfo(undefined);
                history.push('/feed');
              }
            })
          .catch(
            (pException) => {
              toast.error(
                `⚠️ Une erreur s'est produite lors de la déconnexion.`,
                {
                  position: 'top-center',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  progress: undefined,
                },
              );
              console.error(`[ERROR] <SettingsPage> MobileAPI.fetchUserRemoveToken failed :`, pException);
            },
          );
      } else {
        /* The use was logged in but :
         *  - there were no push token associated with the current session
         *  - there is no pushToken currently saved in the local storage
         */
        toast.warn(
          `⚠️ Une erreur s'est produite lors de la déconnexion.`,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            pauseOnFocusLoss: false,
            draggable: true,
            progress: undefined,
          },
        );
        console.error(`[ERROR] <SettingsPage> Failed to find the token to remove (pushToken = ${pushToken})`);
        setAuthToken('');
        updateUserInfo(undefined);
        history.push('/feed');
      }
    } else {
    /* No userInfo, then I shouldn't be considered as "logged in" */
    }
  };

  const onAboutClick: React.MouseEventHandler<HTMLIonItemElement> = (pEvent) => {
    setIsAboutOpened(true);
  };

  const isConnected = userInfo !== undefined;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            Réglages
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher
          slot="fixed"
          onIonRefresh={onRefresh}
        >
          <IonRefresherContent
            pullingIcon="lines"
            pullingText="Rafraîchir"
            refreshingSpinner="lines"
            refreshingText="Chargement..."
          />
        </IonRefresher>
        <SectionTitle title="Mon compte" />
        {
          isConnected &&
            <IonItemGroup>
              <IonItem>
                <IonLabel>
                  Nom
                </IonLabel>
                <IonNote
                  slot="end"
                  color="primary"
                >
                  {userInfo?.user.display_name}
                </IonNote>
              </IonItem>
              <IonItem>
                <IonLabel>
                  Adresse email
                </IonLabel>
                <IonNote
                  slot="end"
                  color="primary"
                >
                  {userInfo?.user.email}
                </IonNote>
              </IonItem>
              <IonItem>
                <IonLabel>
                  Téléphone
                </IonLabel>
                <IonNote
                  slot="end"
                  color="primary"
                >
                  {userInfo?.user.phone}
                </IonNote>
              </IonItem>
              <div style={
                {
                  paddingTop: '20px',
                  paddingBottom: '20px',
                }
              }
              />
            </IonItemGroup>
        }
        <IonItemGroup>
          <IonItem
            button
            detail={false}
            onClick={onLogoutClick}
          >
            <IonLabel>
              {`Se déconnecter`}
            </IonLabel>
            <IonIcon
              slot="end"
              icon={logOutOutline}
            />
          </IonItem>
          <div style={
            {
              paddingTop: '20px',
              paddingBottom: '20px',
            }
          }
          />
        </IonItemGroup>
        <IonItemGroup>
          <IonItem
            button
            detail
            onClick={onAboutClick}
          >
            <IonLabel>
              {'À propos'}
            </IonLabel>
          </IonItem>
          <IonItem
            button
            detail
            href={`${configFile.apiEndpoint}/privacy-mobile`}
            target="_blank"
            rel="noreferrer noopener"
          >
            <IonLabel>
              {'Politique de confidentialité'}
            </IonLabel>
          </IonItem>
        </IonItemGroup>
      </IonContent>
      <AboutModal
        isOpen={isAboutOpened}
        setIsOpen={setIsAboutOpened}
      />
    </IonPage>
  );
};

/* Export SettingsPage component ----------------------- */
export default SettingsPage;
