/* Module imports -------------------------------------- */
import { csFetch } from '../fetch/fetch';

/* Type imports ---------------------------------------- */
import type { FetchResponse } from '../fetch/FetchResponse';
import type { CSFetchFeedReturnType } from './CSFetchFeedReturnType';
import type { CSFetchFeedBodyType } from './CSFetchFeedBodyType';
import { HTTPMethods } from '../../../types/HTTPMethods';

/* fetchFeed parameter type ---------------------------- */
interface FetchFeedParameters {
  offset: number;
}

/* fetchFeed function ---------------------------------- */
export const fetchFeed = async ({ offset } : FetchFeedParameters): Promise<CSFetchFeedReturnType> => {
  const lReturnObj: CSFetchFeedReturnType = {
    posts: [],
    // eslint-disable-next-line camelcase
    posts_images: [],
    statusCode: 0,
  };

  try {
    const lFetchResponse: FetchResponse = await csFetch(
      `/mobileapi/feed?offset=${offset}`,
      HTTPMethods.GET,
    );

    lReturnObj.statusCode = lFetchResponse.statusCode;
    lReturnObj.statusText = lFetchResponse.statusText;
    // console.log(`[DEBUG] <MobileAPI.fetchFeed> Request succeeded, got response :`, lFetchResponse);

    // eslint-disable-next-line no-empty
    if(lFetchResponse.body !== undefined) {
      /* eslint-disable camelcase */
      const {
        posts,
        posts_images,
      }: CSFetchFeedBodyType = lFetchResponse.body as unknown as CSFetchFeedBodyType;
      lReturnObj.posts = posts;
      lReturnObj.posts_images = posts_images;
      /* eslint-enable camelcase */
    }
  } catch(pException) {
    console.error(`[ERROR] <MobileAPI.fetchFeed> POST request failed :`, pException);
    throw new Error(`[ERROR] <MobileAPI.fetchFeed> POST request failed.`);
  }

  return lReturnObj;
};
