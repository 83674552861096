/* Framework imports ----------------------------------- */
import { useState } from 'react';

/* Module imports -------------------------------------- */
import ConfigManager from './ConfigManager/ConfigManager';

/* Type imports ---------------------------------------- */
import {
  News,
  NewsImage,
} from '../types/News';
import { CSFeedPost } from './MobileAPI/feed/CSFeedPost';
import { CSFeedImage } from './MobileAPI/feed/CSFeedImage';

/* Type definitions ------------------------------------ */
interface UseNewsRegistryReturnType {
  news: News[];
  addNews: (pPosts: CSFeedPost[], pPostsImages: CSFeedImage[], pReset: boolean) => void;
  clearNews: () => void;
}

interface NewsImageDestructAcc {
  [id: string]: CSFeedImage;
}

/* useNewsRegistry hook -------------------------------- */
export const useNewsRegistry = (): UseNewsRegistryReturnType => {
  const [ news, setNews ] = useState<News[]>([]);

  const reformatImages = (pPostsImages: CSFeedImage[]): NewsImageDestructAcc => {
    return pPostsImages.reduce<NewsImageDestructAcc>(
      (pAcc: NewsImageDestructAcc, pImage: CSFeedImage) => {
        return {
          ...pAcc,
          [pImage.id]: pImage,
        };
      },
      {},
    );
  };

  const addNews = (pPosts: CSFeedPost[], pPostsImages: CSFeedImage[], pReset: boolean = false): void => {
    const lImages = reformatImages(pPostsImages);
    const lNews: News[] = pPosts.reduce<News[]>(
      (pAcc: News[], pPost: CSFeedPost) => {
        // console.log(`[DEBUG] <useNewsRegistry.addNews> Reducing posts, processing post :`, pPost);
        return pAcc.concat(
          [
            {
              id: pPost.id,
              title: pPost.display_name,
              description: pPost.description,
              date: pPost.date,
              stageIds: pPost.stage_ids === false || pPost.stage_ids.length < 1 ?
                [] :
                pPost.stage_ids,
              images: (pPost.image_ids)
                .reduce(
                  (pAcc: NewsImage[], pId: number) => {
                    return pAcc.concat(
                      [
                        {
                          source: `${ConfigManager.getAPIURL()}/mobileapi/image/${pId}/${lImages[pId].display_name}`,
                          description: lImages[pId].description,
                          id: lImages[pId].id,
                        },
                      ],
                    );
                  },
                  [],
                ),
            },
          ],
        );
      },
      [],
    );

    setNews(pReset ? lNews : news.concat(lNews));
  };

  const clearNews = (): void => {
    setNews([]);
  };

  return {
    news,
    addNews,
    clearNews,
  };
};
