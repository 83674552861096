const defaultDateFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export const formatDate = (date: Date, options?: Intl.DateTimeFormatOptions): string => {
  return (date).toLocaleDateString(
    'fr-FR',
    {
      ...defaultDateFormatOptions,
      ...options,
    },
  );
};

export const formatDateStr = (dateStr: string, options?: Intl.DateTimeFormatOptions): string => {
  return formatDate(new Date(dateStr), options);
};

export const formatDateForSQL = (date: Date): string => {
  /* Exported format is '%Y-%m-%d %H:%M:%S' */

  return date.toISOString().slice(0, 19).replace('T', ' ');
};

export const formatDateStrForSQL = (dateStr: string): string => {
  return formatDateForSQL(new Date(dateStr.replace(' ', 'T')));
};

export const formatDateForMobile = (date: Date): string => {
  /* Exported format is '%Y-%m-%dT%H:%M:%S' */

  /* This is especially useful for iOS
   * See : https://stackoverflow.com/a/13363791/8007661
   */

  return date.toISOString().slice(0, 19);
};

export const formatDateStrForMobile = (dateStr: string): string => {
  /* This is especially useful for iOS
   * See : https://stackoverflow.com/a/13363791/8007661
   */

  return formatDateForMobile(new Date(dateStr.replace(' ', 'T')));
};
