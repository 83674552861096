/* Framework imports ----------------------------------- */
import React from 'react';

/* Component imports ----------------------------------- */
import { IonSkeletonText } from '@ionic/react';

/* CSFeedCardSkeleton component ------------------------ */
const CSFeedCardSkeleton: React.FC = () => (
  <div
    className="csfeed-card"
    style={
      {
        padding: '.5rem',
      }
    }
  >
    <IonSkeletonText
      animated
      style={{ width: '88%', lineHeight: '24px' }}
    />
    <IonSkeletonText
      animated
      style={{ width: '68%' }}
    />
    <IonSkeletonText
      animated
      style={{ width: '100%', lineHeight: '250px' }}
    />
    <IonSkeletonText
      animated
      style={{ width: '88%' }}
    />
    <IonSkeletonText
      animated
      style={{ width: '48%' }}
    />
  </div>
);

/* Export CSFeedCardSkeleton component ----------------- */
export default CSFeedCardSkeleton;
