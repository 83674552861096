/* Framework imports ----------------------------------- */
import {
  useRef,
  useEffect,
} from 'react';

/* useIsMounted hook ----------------------------------- */
export const useIsMounted = (): boolean => {
  const isMounted = useRef(false);

  useEffect(
    () => {
      isMounted.current = true;

      return () => {
        isMounted.current = false;
      };
    },
    [],
  );

  return isMounted.current;
};
