/* Framework imports ----------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react';
import { Capacitor } from '@capacitor/core';

/* Module imports -------------------------------------- */
import ConfigManager from '../../helpers/ConfigManager/ConfigManager';

/* Component imports ----------------------------------- */
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonModal,
  useIonViewDidLeave,
} from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import SectionTitle from '../SectionTitle/SectionTitle';
import MobileAPI from '../../helpers/MobileAPI/MobileAPI';
import { toast } from 'react-toastify';
import SanitizeHTML from '../SanitizeHTML/SanitizeHTML';

/* AboutModal component prop types --------------------- */
interface AboutPageProps {
  isOpen: boolean;
  setIsOpen: (arg0: boolean) => void;
}

/* AboutModal component -------------------------------- */
const AboutModal: React.FC<AboutPageProps> = ({ isOpen, setIsOpen }) => {
  const [ aboutText, setAboutText ] = useState<string>('');
  const [ titleClickCounter, setTitleClickCounter ] = useState<number>(0);

  const clickedAboutTitle = () => {
    setTitleClickCounter(
      (pPrevVal: number) => pPrevVal + 1,
    );
  };

  const loadAboutData = async (): Promise<void> => {
    try {
      const { statusCode, reference } = await MobileAPI.fetchReference('mycs_about');
      console.log(reference);
      if(statusCode === 200 && reference) {
        setAboutText(reference.content);
      }
    } catch(pException) {
      console.error(`[ERROR] <AboutModal> Failed to fetch the documents :`, pException);
      toast.error(
        `⚠️ Une erreur s'est produite en récupérant la section à propos.`,
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
        },
      );
    }
  };

  useEffect(() => {
    loadAboutData()
      .catch(
        (pException) => {
          console.error(`[ERROR] <AboutModal> loadAboutData() failed :`, pException);
        },
      );
  }, []);

  useIonViewDidLeave(
    () => {
      setTitleClickCounter(0);
    },
  );

  return (
    <IonModal
      isOpen={isOpen}
      onWillDismiss={() => isOpen && setIsOpen(false)}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => setIsOpen(false)}>
              <IonIcon
                icon={arrowBack}
                style={{ fontSize: '18px', color: 'white' }}
              />
            </IonButton>
          </IonButtons>
          <IonTitle onClick={clickedAboutTitle}>
            À Propos
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <SectionTitle title="À propos" />
        <SanitizeHTML
          html={aboutText}
          style={{ padding: '1rem' }}
        />
        {
          titleClickCounter > 10 &&
            <div>
              <SectionTitle title="Informations sur l'application" />
              <p style={{ padding: '0 1rem' }}>
                {`API endpoint is ${ConfigManager.getAPIURL()}`}
              </p>
              <p style={{ padding: '0 1rem' }}>
                {`App version is ${ConfigManager.getAppVersion()}`}
              </p>
              <p style={{ padding: '0 1rem' }}>
                {`App platform is ${Capacitor.getPlatform()}`}
              </p>
            </div>
        }
      </IonContent>
    </IonModal>
  );
};

/* Export AboutModal component ------------------------- */
export default AboutModal;
