/* Framework imports ----------------------------------- */
import React from 'react';

/* Module imports -------------------------------------- */
import ConfigManager from '../../../helpers/ConfigManager/ConfigManager';

/* Component imports ----------------------------------- */
import { IonIcon } from '@ionic/react';
import { cloudDownloadOutline } from 'ionicons/icons';

/* Type imports ---------------------------------------- */
import type { CSDocument } from '../../../helpers/MobileAPI/documents/CSDocument';

/* Styling imports ------------------------------------- */
import './CSDocumentListItem.css';

/* Helper functions ------------------------------------ */

/* CSDocumentListItem component prop types ------------- */
interface CSDocumentListItemProps {
  document: CSDocument;
}

/* CSDocumentListItem component ---------------------------- */
const CSDocumentListItem: React.FC<CSDocumentListItemProps> = ({ document }) => {
  const lAPIURL = ConfigManager.getAPIURL();

  return (
    <div className="cs-document-list-item">
      <div className="cs-document-list-item-info">
        <div className="cs-document-list-item-name">
          {document.display_name}
        </div>
        <div className="cs-document-list-item-description">
          {document.description}
        </div>
      </div>
      <form
        method="get"
        action={encodeURI(`${lAPIURL}/mobileapi/file/${document.id.toString()}/${document.display_name}`)}
      >
        <button
          className="cs-document-list-item-download-button"
        >
          <IonIcon
            className="cs-document-list-item-download-button-icon"
            icon={cloudDownloadOutline}
            size="large"
          />
        </button>
      </form>
    </div>
  );
};

/* Export CSDocumentListItem component ----------------- */
export default CSDocumentListItem;
