/* Framework imports ----------------------------------- */
import React, { useEffect } from 'react';
import {
  StatusBar,
  Style,
} from '@capacitor/status-bar';

/* Module imports -------------------------------------- */
import { useLocalStorage } from '../../helpers/useLocalStorage';

/* Component imports ----------------------------------- */
import {
  IonContent,
  IonModal,
} from '@ionic/react';
import { motion } from 'framer-motion';
import CSButton from '../CSButton/CSButton';

/* Style imports --------------------------------------- */
import './FirstStartModal.css';

/* Internal variables ---------------------------------- */
const container = {
  hidden: { opacity: 0, duration: 0.5 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.2,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const item2 = {
  hidden: { opacity: 0, scale: 1.2 },
  visible: {
    scale: 0.7,
    opacity: 1,
  },
};

/* LoginModal component --------------------------------- */
const FirstStartModal: React.FC = () => {
  const [ everOpened, setEverOpened ] = useLocalStorage('ever_opened', 'no');
  const isOpen = !(everOpened === 'yes');

  const close = () => {
    setEverOpened('yes');
  };

  useEffect(
    () => {
      StatusBar.setStyle(
        {
          style: isOpen ? Style.Light : Style.Dark,
        },
      )
        .catch(
          (pException) => {
            console.error(`[ERROR] <FirstStartModal> Failed to set the status bar to a ${isOpen ? 'dark' : 'light'} style :`, pException);
          },
        );
    },
    [ isOpen ],
  );

  return (
    <IonModal
      isOpen={isOpen}
      onWillDismiss={() => close()}
      cssClass="fullscreen-modal"
    >
      <IonContent
        fullscreen
        scrollY={false}
      >
        <motion.div
          variants={container}
          initial="hidden"
          animate="visible"
        >
          <div className="modal-cs-icon">
            <motion.img
              variants={item2}
              src="/assets/logos/bloomdayz-full-logo-transparent.svg"
            />
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path
              fill="#FFF8EB"
              fillOpacity="1"
              d="M0,192L30,186.7C60,181,120,171,180,149.3C240,128,300,96,360,122.7C420,149,480,235,540,245.3C600,256,660,192,720,165.3C780,139,840,149,900,165.3C960,181,1020,203,1080,202.7C1140,203,1200,181,1260,144C1320,107,1380,53,1410,26.7L1440,0L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"
            />
          </svg>
          <motion.div
            variants={item}
            className="firststart-header"
          >
            Bienvenue sur Bloomdayz
          </motion.div>
          <motion.div
            variants={item}
            style={{ fontSize: '25px', paddingTop: 5 }}
            className="firststart-subheader"
          >
            👋
          </motion.div>
          <div style={{ padding: '15px 15px' }}>

            <motion.div
              variants={item}
              style={{ textAlign: 'center', padding: 30 }}
            >
              <p style={{ fontWeight: 'bold' }}>
                Suivez l'actualité Bloomdayz et profitez de réductions inédites !
              </p>
            </motion.div>
            <motion.div
              variants={item}
              style={{ textAlign: 'center', padding: '5px 30px 5px 30px' }}
            >
              <p style={{ color: '#888', fontWeight: 600 }}>
                Accédez aux photos de vos enfants en live pendant les stages en vous connectant avec votre compte
                {' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://bloomdayz.fr"
                >
                  bloomdayz.fr
                </a>
                .
              </p>
            </motion.div>
            <motion.div variants={item}>
              <CSButton
                type="submit"
                onClick={() => close()}
              >
                C'est parti !
              </CSButton>
            </motion.div>
          </div>
        </motion.div>
      </IonContent>
    </IonModal>
  );
};

/* Export LoginModal component -------------------------- */
export default FirstStartModal;
