/* Framework imports ----------------------------------- */

/* Module imports -------------------------------------- */

/* Type imports ---------------------------------------- */
import type { IConfig } from '../../types/IConfig';

/* Configuration imports ------------------------------- */
import configFile from '../../config/config.json';

/* ConfigManager methods ------------------------------- */
const getConfig = (): IConfig => {
  return configFile;
};

const getAPIURL = (): string => {
  const lDefaultAPIEndpoint = 'https://cote-sports-cotesports-public.loginline.dev';

  if(configFile.appVersion !== undefined) {
    return configFile.apiEndpoint || lDefaultAPIEndpoint;
  } else {
    return lDefaultAPIEndpoint;
  }
};

const getAppVersion = (): string => {
  if(configFile.appVersion !== undefined) {
    return configFile.appVersion;
  } else {
    return 'unknown';
  }
};

/* Export ConfigManager -------------------------------- */
export default {
  getConfig,
  getAPIURL,
  getAppVersion,
};
